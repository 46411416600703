@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif !important;
}
ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}
a{
  text-decoration: none;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #F7F9FB;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #D9D9D9;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.c-box::-webkit-scrollbar-track {
  background: #fff;
} 

.login-page {
  background: #f1f1f1;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 57px;
}
.login-container {
  width: 400px;
  margin: 0 auto;
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  text-align: center;
  box-shadow: 0 0 10px 7px rgba(0,0,0,0.125);
}
.login-container h3 {
  font-size: 19px;
  font-weight: 700;
  margin: 19px 0 17px 0;
}
.login-container button {
  background: #e04a32;
  border: none;
  color: #fff;
  display: block;
  margin: 0 auto;
  padding: 10px 20px 10px 56px;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
}
.login-container button span {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e8604a;
  padding: 2px 9px;
}
.my-team-box-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header {
  border-bottom: 2px solid #E1E3E5;
  padding: 10px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 999;
}
.header-right {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 0 18px 0 0;
  align-items: center;
}
.header-dropdown button {
  border: none;
  width: 33px;
  height: 33px;
  background: #F3F1EE;
  color: #859AC3;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  border-radius: 50%;
  padding: 0;
  text-transform: uppercase;
}
.header-right label {
  color: #3A404F;
  text-align: right;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  text-transform: capitalize;
}
.dropdown-toggle::after {
  position: absolute;
  right: -16px;
  top: 12px;
}
.btn-primary:hover {
  color: #859AC3;
  background-color: #F3F1EE;
  border-color: #F3F1EE;
}
.header-right .dropdown-menu {
  border-radius: 7px;
  background: var(--white-100, #FFF);
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25);
  min-width: 260px;
  padding: 0;
  margin: 0;
}
.dopdown-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 15px;
}
.dopdown-top h5 {
  margin: 0;
  color: #859AC3;
  text-align: left;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.dopdown-top span {
  border: none;
  width: 33px;
  height: 33px;
  background: #F3F1EE;
  color: #859AC3;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  border-radius: 50%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}
.header-right .dropdown-menu a {
  color: #3A404F;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
  text-decoration: none;
  padding: 7px 15px;
}
.common-panel {
  padding: 81px 0 20px 0;
}
.landing-story-box {
  background: #E0EDF0;
  border-left: 10px solid #263B75;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 10px 0px rgba(172, 179, 196, 0.25);
  border-radius: 12px;
}
.landing-story-box ul {
  display: flex;
  align-items: center;
  gap: 23px;
}
.landing-story-box h5 {
  color: #3A404F;
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
}
.landing-story-box p {
  margin: 0;
  color: #3A404F;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
}
.btn-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 31px 0 22px 0;
}
.btn-text h3 {
  color: #3A404F;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  margin: 0;
}
.home-nav-tabs {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}
.jobBtn {
  border-radius: 10px;
  background: #263B75;
  display: flex;
  padding: 23px 32px;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  align-items: center;
  gap: 8px;
  border: none;
}
.home-nav-tabs .nav-link {
  color: #ACB3C4;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  padding: 7px 18px;
  border-radius: 15px;
  position: relative;
}
.home-nav-tabs .nav-link.active {
  background: #F1F1F1;
  color: #263B75;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.home-nav-tabs .nav-link.active::before {
  content: "";
  position: absolute;
  bottom: -8px;
  height: 3px;
  width: 100%;
  left: 0;
  right: 0;
  background: #263B75;
  border-radius: 5px;
}
.home-nav-tabs .nav-link img {
  opacity: 0.5;
  margin-right: 4px;
}
.home-nav-tabs .nav-link.active img{
  opacity: 1;
}
.nodata-home {
  width: 100%;
  margin: 64px 0;
  max-width: 100%;
  text-align: center;
  border-radius: 10px;
  border: 2px dashed var(--UI_Box_Finished, #87C4D0);
  background: var(--white-100, #FFF);
  padding: 83px 20px;
}
.nodata-home h4 {
  color: var(--YSF-main-color, #3A404F);
  text-align: center;
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.nodata-home p {
  color: #3A404F;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin: 20px 0;
}
.home-tabs ul.nav.nav-tabs {
  border: none;
  display: flex;
  gap: 9px;
}
.home-tabs ul.nav.nav-tabs button {
  border-radius: 5px 5px 0px 0px;
  background: rgba(172, 179, 196, 0.20);
  border: none;
  color: var(--Primary-Dark-Blue, #3A404F);
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  display: block;
  padding: 16px 24px;
}
.home-tabs ul.nav.nav-tabs button.active {
  background: #E7F3F6;
  color: #87C4D0;
}
.home-tabs table {
  width: 100%;
  background: #fff;
}
.home-tabs table th {
  background: rgba(172, 179, 196, 0.20);
  color:  #3A404F;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  padding: 20px 15px;
  text-align: left;
}
.home-tabs table td {
  color:  #3A404F;
  text-align: left;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  padding: 21px 15px;
  border-bottom: 1px solid #ACB3C4;
}
.home-tabs table td span {
  color: #3A404F;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  position: relative;
  padding: 0 0 0 17px;
}
.home-tabs table td span.inprogress::before {
  content: "";
  position: absolute;
  top: 8px;
  left: 0;
  background: #F5B21A;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.home-tabs table td span.completed::before {
  content: "";
  position: absolute;
  top: 4px;
  left: 2px;
  height: 13px;
  width: 6px;
  border-bottom: 2px solid #2ABF92;
  border-right: 2px solid #2ABF92;
  transform: rotate(45deg);
}
.table-checkbox {
  border-radius: var(--8, 8px);
  background: rgba(172, 179, 196, 0.20);
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 9px 12px;
  width: 154px;
}
.checkbox-container {
  border-radius: 2.333px;
  border: 1.167px solid #ACB3C4;
  background: #D9D9D9;
  width: 14px;
  height: 14px;
  position: relative;
}
.table-checkbox label {
  margin: 0;
  color:#ACB3C4;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.table-checkbox.active {
  background: rgba(245, 178, 26, 0.20);
}
.table-checkbox.active label {
  color: #F5B21A;
}
.table-checkbox.active .checkbox-container{
  border: 1.167px solid #F5B21A;
  background: #F5B21A;
}
.table-checkbox.active .checkbox-container::after {
  position: absolute;
  top: 1px;
  height: 8px;
  width: 4px;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: rotate(45deg);
  left: 4px;
  right: 0;
  content: "";
}
.main-layout {
  padding-top: 58px;
  /* padding-bottom: 70px; */
  /* min-height: calc(100vh - 116px); */
}
.main-layout .container-fluid {
  padding-left: 27px;
  /* min-height: calc(100vh - 116px); */
  padding-right: 0;
}
.steper-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  box-shadow: 0px 12.45021px 31.12552px 0px rgba(38, 59, 117, 0.20);
  z-index: 99;
  padding: 12px 15px;
  gap: 31px;
}
.steper-footer label {
  margin: 0;
  color: #3A404F;
  font-size: 14px;
}
.steper-footer ul {
  display: flex;
  gap: 13px;
}
.steper-footer ul li span {
  width: 14px;
  height: 14px;
  background: #D9D9D9;
  border-radius: 50%;
  display: block;
}
.steper-footer ul li span.current {
  width: 46px;
  border-radius: 30px;
  background: #87C4D0;
}
.common-left {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  position: relative;
}
.pageHeading {
  margin-top: 20px;
}
.pageHeading h1 {
  color: #3A404F;
  font-family: Poppins;
  font-size: 2vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.pageHeading h1 img {
  margin-right: 14px;
}
.default-card {
  border-radius: 20px;
  border: 1.358px solid var(--black-10, rgba(28, 28, 28, 0.10));
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.125);
  /* overflow: hidden; */
  /* min-height: 88%; */
}
.default-card-header {
  background: #E7F3F6;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  gap: 15px;
}
.default-card-header span {
  background: #87C4D0;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 13px;
}
.default-card-header-text {
  width: 74%;
}
.default-card-header-text h3 {
    color: #3A404F;
    font-weight: 500;
    margin: 0 0 6px 0;
    font-size: 30px;
    font-family: 'Poppins';
}
.default-card-header-text p {
  color: #3A404F;
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.2;
  margin: 0;
  /* font-size: .9vw; */
}
.default-card-header-img {
  margin-left: auto;
}
.default-card-header-img img {
  width: 90px;
}
.input-field-list {
  margin-bottom: 22px;
}
.default-card-body {
  padding: 19px 15px;
}
.input-field-label {
  display: flex;
  gap: 15px;
  margin-bottom: 30px;
}
.input-field-label span {
  width: 35px;
  height: 35px;
  background: #3A404F;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.input-field-info {
  width: 91%;
}
.input-field-info h4 {
  color: #3A404F;
  font-family: 'Poppins', sans-serif;
  /* font-size: 24px; */
  font-style: normal;
  font-weight: 600;
  line-height: 27.153px;
  margin: 0 0 3px 0;
  font-size: 1.3vw;
}
.input-field-info p {
  margin: 0;
  color: #ACB3C4;
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 25.153px;
}
.custom-drop {
  border-radius: 15px;
  border: 2px dashed #49C0B4;
  background: var(--white-100, #FFF);
  height: 178px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  padding: 30px;
  cursor: pointer;
}
.fileupload-option {
  text-align: center;
}
.fileupload-option p {
  color: var(--Blue-Grey, #ACB3C4);
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  margin: 8px 0 0 0;
}
.cv-output {
  border-radius: 15px;
  border: 1px dashed #D0D2D9;
  background: #F6FAFB;
  height: 179px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.cv-output label {
  color: var(--Secondary-Blue-Teal, #87C4D0);
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 27.153px;
}
.cv-output label button {
  border: none;
  padding: 0;
  background: transparent;
  margin-left: 5px;
}
h5.success-upload-cv {
  color: #3A404F;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 16px 0 0 0;
}
h5.success-upload-cv img {
  margin: 0 6px 0 0;
}
.linkdinUrl .form-control {
  border-radius: 7px;
  border: 1px solid var(--Secondary-Blue-Gray, #ACB3C4);
  background: var(--white-100, #FFF);
  color: #3A404F;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
  padding: 14px 127px 14px 15px !important;
  font-size: 20px;
}
.linkdinUrl .form-control::placeholder{
  color: #ACB3C4;
}

.linkdinUrl button {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 5px 5px 0;
  background: #E7F3F6;
  display: inline-block;
  padding: 4px 31px 4px 22px;
  color: #3A404F;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border: none;
  bottom: 0;
  height: 100%;
}
.linkdinUrl {
  position: relative;
  overflow: hidden;
  margin-top: 0px;
}
.linkdinUrl button:hover {
  background: #87C4D0;
}
.or-sep::before {
  content: "";
  position: absolute;
  top: 13px;
  left: 0;
  background: #ACB3C4;
  height: 2px;
  width: 45%;
}
.or-sep::after {
  content: "";
  position: absolute;
  top: 13px;
  right: 0;
  background: #ACB3C4;
  height: 2px;
  width: 45%;
}
.or-sep {
  position: relative;
  text-align: center;
  margin-bottom: 19px;
}
.or-sep span {
  color: var(--YSF-main-color, #3A404F);
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.comonn-right {
  background: #F7F9FB;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.no-data {
  width: 400px;
  max-width: 100%;
  text-align: center;
}
.no-data h5 {
    color: #3A404F;
    text-align: center;
    font-family: Poppins;
    font-size: 18.333px;
    font-style: normal;
    font-weight: 600;
    line-height: 27.153px;
    margin: 10px 0;
}
.no-data p {
  color: #ACB3C4;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  margin: 0;
}


.common-loaderStyle ul li {
  padding: 4px 10px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.loaderone-right {
  width: 100%;
}

.card__title {
  height: 12px;
  margin-bottom: 7px;
  border-radius: 40px;
  width: 100%;
}

.card__skeleton.card__title:last-child {
  margin: 0;
}

.card__skeleton {
  overflow: hidden;
  position: relative;
  background-image: linear-gradient(to right, rgb(237, 237, 237), rgb(255, 255, 255));
}

.card__skeleton::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.3);
  height: 100%;
  animation: shine infinite 1.5s ease-in-out;
}

@keyframes shine {
  from {
      transform: skew(2deg) translateX(0%);
  }

  to {
      transform: skew(2deg) translateX(200%);
  }
}

.loader {
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 4px 10px 0px rgba(172, 179, 196, 0.25);
  width: 95%;
  overflow: hidden;
}

.loader-top {
  background: var(--Pallete_Radial-FIll, radial-gradient(189.11% 140.6% at 117.55% 22.35%, #E6F7E6 0%, #A5DED2 32.28%, #87C4D0 52.38%, #637B9E 76.06%, #42416B 100%));
  padding: 10px 15px;
}

.loader-top h4 {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #fff;
  margin: 0;
}

.loader-top h4 img {
  margin-right: 5px;
}

.common-loaderStyle {
  padding: 12px 6px;
}

.output-view {
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 4px 10px 0px rgba(172, 179, 196, 0.25);
  width: 90%;
  overflow: hidden;
}
.output-view.job-profile{
  border-radius:0px;
  background: transparent;
  box-shadow:none;
  width: 90%;
  overflow: hidden;
}

.output-view-top {
  background: var(--Pallete_Radial-FIll, radial-gradient(189.11% 140.6% at 117.55% 22.35%, #E6F7E6 0%, #A5DED2 32.28%, #87C4D0 52.38%, #637B9E 76.06%, #42416B 100%));
  padding: 10px 15px;
}

.output-view h4 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  color: #fff;
  margin: 0;
}

.output-view-body {
  padding: 10px;
}

.output-view-body p {
  color: #3A404F;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

.bottom-navigation {
  position: absolute;
  right: 40px;
  bottom: 20px;
  display: flex;
  align-items: center;
}

.bottom-navigation button {
  background: #E7F3F6;
  border: none;
  display: block;
  padding: 10px 13px;
}

.bottom-navigation button:hover {
  background: #87C4D0;
}

.bottom-navigation button.next-button {
  border-radius: 0 5px 5px 0;
}

.bottom-navigation button.prev-button {
  border-radius: 5px 0px 0px 5px;
}

.bottom-navigation button[disabled] {
  background: #D0D2D9;
}
.output-view h4 img {
  margin-right: 5px;
/* filter: brightness(6); */
}
.output-view-body.step-oneview h5 {
  background: #E7F3F6;
  color: var(--Primary-Dark-Blue, #3A404F);
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.153px;
  margin: -10px -10px 10px -10px;
  padding: 15px;
}
.output-view-body.step-oneview ul {
  /* max-height: 62vh;
  overflow-y: scroll;
  min-height: auto; */
  padding: 10px 20px 0 0;
}
.output-view-body.step-oneview ul li {
  border-radius: 6px;
  background: #FFF;
  color: #32394E;
  font-family: 'Roboto';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.36px;
  display: block;
  padding: 15px 10px 12px 0px;
  margin-bottom: 13px;
  position: relative;
  box-shadow: 0px 3.419px 8.548px 0px rgba(0, 0, 0, 0.10);
}
/* .output-view-body.step-oneview ul li {
  border-radius: 6px;
  background: #FFF;
  color: #32394E;
  font-family: 'Roboto';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.36px;
  display: block;
  padding: 50px 10px 7px 0px;
  margin-bottom: 13px;
  position: relative;
  box-shadow: 0px 3.419px 8.548px 0px rgba(0, 0, 0, 0.10);
} */
.output-view-body.step-oneview ul li label {
  padding-left: 50px;
  font-size: 20px;
  color: #32394E;
  font-size: 15px;
  font-family: Roboto;
}
.cover-letter-sep > div {
  color: #32394E;
  font-size: 15px;
  font-family: Roboto;
}
.output-view-body.step-oneview ul li.selected label{
  color: #87CEDC;
  text-decoration: line-through;
}
h4.special_heading img {
  position: absolute;
  left: 0;
  top: 7px;
  filter: brightness(0);
}
h4.special_heading {
  color: var(--Primary-Dark-Blue, #3A404F);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 27.153px;
  margin: 21px 0 14px 0;
  position: relative;
  padding: 0 0 0 46px;
}
/* .output-view-body.step-oneview ul li span {
  position: absolute;
  top: 52px;
  left: 2%;
  transform: translate(-2%, -2%);
  cursor: pointer;
} */
.output-view-body.step-oneview ul li span {
  position: absolute;
  top: 50%;
  left: 2%;
  transform: translate(-2%, -50%);
  cursor: pointer;
}
.output-view-body.step-oneview ul li.selected::before{
  background: #BBE3EB;
}
button.crosBtn {
  position: absolute;
  right: 0;
  top: -7px;
  border: none;
  background: transparent;
  padding: 0;
  z-index: 1;
  display: none;
}
.output-view-body.step-oneview ul li.selected:hover button.crosBtn{
  display: block;
}
.deletemodal h3 {
  color: var(--Primary-Dark-Blue, #3A404F);
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  margin: 0 0 19px 0;
}
.deletemodal h6 {
  color: var(--Primary-Dark-Blue, #3A404F);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.deletemodal ul {
  display: flex;
  justify-content: center;
  gap: 25px;
  margin-top: 24px;
}
.deletemodal ul button.borderBtn {
  border-radius: var(--8, 8px);
  border: 1px solid rgba(172, 179, 196, 0.50);
  color: var(--YSF-main-color, #3A404F);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: transparent;
  display: block;
  padding: 10px 35px;
}
.deletemodal ul button.solidBtn {
  border-radius: var(--8, 8px);
  background: var(--Orange, #E27454);
  color: var(--Tertiary-White, var(--white-100, #FFF));
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: block;
  border: none;
  padding: 11px 35px;
}
.themeBtn {
  border-radius: var(--8, 8px);
  border: 1px solid  #87C4D0;
  background: #87C4D0;
  color: #3A404F;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: inline-block;
  padding: 14px 27px;
}


.nodata-home .themeBtn {
  border-radius: var(--8, 8px);
  border: 1px solid  #3A404F;
  background: #3A404F;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline-block;
  padding: 10px 27px;
}
.jobsinput-field {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 33px;
}
.jobsinput-field label {
  color: #3A404F;
  font-family: Poppins;
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.36px;
  width: 30%;
  margin: 0;
}
.jobsinput-field .form-control {
  border-radius: 7px;
  border: 1px solid var(--Secondary-Blue-Gray, #ACB3C4);
  background: var(--white-100, #FFF);
  color: #3A404F;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
  padding: 14px 15px;
  width: 70%;
  font-size: 20px;
}
.jobsinput-field .form-control::placeholder{
  color: #ACB3C4;
}
.jobsinput-field .linkdinUrl {
  width: 70%;
}
.jobsinput-field .linkdinUrl .form-control {
  width: 100%;
}
.output-view-body.step-jobsview h3 {
  color: #000;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
}
.output-view-body.step-jobsview p {
  color:#32394E;
  font-family: Poppins;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.36px;
}
.preview-modal h2 {
  color: var(--YSF-main-color, #3A404F);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  margin: 0 0 48px 0;
}
.preview-modal h4 {
  color: var(--YSF-main-color, #3A404F);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin: 27px 0 15px 0;
}
.preview-modal p {
  color: #ACB3C4;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  min-height: 80px;
}
.preview-modal .solidBtn {
  border-radius: 8px;
  background: var(--UI_Box_Finished, #87C4D0);
  color: var(--Tertiary-White, var(--white-100, #FFF));
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  display: inline-block;
  padding: 10px 22px;
  text-transform: capitalize;
}

.preview-modal::before {
  content: "";
  position: absolute;
  left: 0;
  width: 1px;
  background: #ACB3C4;
  top: 22%;
  height: 69%;
  right: 0;
  margin: 0 auto;
  bottom: 22%;
}
button.close_Icon {
  position: absolute;
  top: 18px;
  right: 14px;
  background: transparent;
  border: none;
}
.signup-container {
  width: 72%;
  margin: 0 auto;
  max-width: 100%;
  border-radius: 50px;
  background: rgba(196, 242, 251, 0.20);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: 75vh;
}
.signup-container-lft .slick-list {
  height: 100%;
}
.signup-container-lft .slick-track {
  height: 100%;
}
.signup-container-lft .slick-slide > div {
  height: 100%;
}
.slider-animate {
  text-align: center;
  padding: 20px;
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.slider-animate h3 {
  color: #49C0B4;
  text-align: center;
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.slider-animate h3 span {
  background: linear-gradient(90deg, #666986 52.1%, rgba(47, 46, 65, 0.35) 91.41%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.slider-animate img {
  width: 400px;
  display: block;
  margin: 0 auto;
}
.signup-container-rht {
  border-radius: 45px;
  background: var(--white-100, #FFF);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: 100%;
  padding: 30px 25px;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 95%;
  margin-left: auto;
}
.signup-container-rht h3 {
  color: #3F3D56;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 25px 0;
}
.signup-container-rht .google_btn {
  border-radius: 20px;
  border: 3px solid #E4D9D9;
  padding: 8px 26px;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 auto;
  background: #3A404F;
}
.signup-container-rht h5 {
  text-align: left;
  color: var(--Title, #18A8B2);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 272px;
  margin: 10px auto 0 auto;
}
.signup-container-rht h5 a {
  color: #BDADAD;
  text-decoration: underline;
}
.signup-container-rht h6 {
  margin-top: auto;
  color: #3F3D56;
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
  text-align: center;
}
.signup-container-rht h6 a{
  color: #3F3D56;
  text-decoration: underline;
}
.signup-container-rht-inner {
  margin-top: 40px;
}
button.next-button.selectedBtn {
  background: #87C4D0;
  font-weight: 700;
  color: #3A404F;
}
.common-panel > .container-fluid {
  padding: 0 80px;
}
.concern-box {
  border-radius: 12px;
  border: 1px solid #ACB3C4;
  background: var(--white-100, #FFF);
  padding: 20px 20px;
  position: relative;
}
.concern-box h3 {
  margin: 0;
  position: absolute;
  top: -19px;
  left: 29px;
  background: #fff;
  color: var(--YSF-Blue, #263B75);
  font-family: 'Poppins';
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  padding: 0 5px;
}
.concern-box h4 {
  color: #2F4787;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  margin: 4px 0 20px 0;
}
.concern-box .ConcernList li {
  border-radius: 8px;
  border: 1px solid  #B6BACA;
  background: #FFF;
  position: relative;
  padding: 10px 15px 10px 60px;
  margin-bottom: 15px;
  color:  #3A404F;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.153px;
  cursor: pointer;
  transition: .3s ease-in-out;
}
.concern-box .ConcernList li::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 2%;
  border: 1px solid #87C4D0;
  width: 29px;
  height: 28px;
  border-radius: 4px;
  transform: translate(-1%, -50%);
  transition: .3s ease-in-out;
}
.concern-box .ConcernList li::after {
  content: "\f00c";
  position: absolute;
  top: 50%;
  left: 2%;
  width: 29px;
  height: 28px;
  border-radius: 4px;
  transform: translate(-1%, -50%);
  background: #87C4D0;
  font-family: fontawesome;
  color: #fff;
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  transition: .3s ease-in-out;
}
.concern-box .ConcernList li.selected {
  background: #E0EDF0;
  transition: .3s ease-in-out;
}
.concern-box .ConcernList li.selected::after{
  display: flex;
  transition: .3s ease-in-out;
}
/* .scroll_position {
  min-height: auto;
  max-height: 250px;
  overflow-y: scroll;
} */
button.addBtn {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #87C4D0;
  background: #FFF;
  color:  #87C4D0;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.153px;
  padding: 14px 15px;
  transition: .3s ease-in-out;
}
button.addBtn:hover{
  background: #87C4D0;
  color: #fff;
  transition: .3s ease-in-out;
}
.concern-box .ConcernList li:hover{
  background: #E0EDF0;
  transition: .3s ease-in-out;
}
button.saveBtn {
  border-radius: 8px;
  background: #87C4D0;
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 27.153px;
  border: none;
  padding: 13px 39px;
  margin: 0 24px 0 0;
}
button.EditBtn {
  position: absolute;
  top: -16px;
  right: 27px;
  color: var(--Secondary-Blue-Gray, #ACB3C4);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 27.153px;
  text-decoration-line: underline;
  border: none;
  background: #fff;
}
.lay-box.collapsed {
  height: 87px;
  overflow: hidden;
  padding-top: 14px;
  opacity: 0.5;
}
.des-result p {
  color: var(--Primary-Dark-Blue, #3A404F);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.153px;
  margin: 0;
}
/* .des-result {
  border-radius: 8px;
  background: rgba(245, 245, 245, 0.50);
  padding: 9px;
} */
.concern-l{
  min-height: calc(100vh - 116px);
}
.concerndefaultloader {
  width: 75%;
}
.loader-list {
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 15px 0;
}
.loader-skelen-square {
  border-radius: 2px;
  background: linear-gradient(90deg, #D8DEDF 24.65%, rgba(217, 217, 217, 0.23) 97.99%);
  width: 20px;
  height: 20px;
}
.loader-list ul {
  width: 100%;
}
.loader-list ul li {
  background: linear-gradient(90deg, #D8DEDF 24.65%, rgba(217, 217, 217, 0.23) 97.99%);
  width: 100%;
  height: 11px;
  border-radius: 30px;
  margin-bottom: 6px;
}
.loader-list ul li:last-child {
  width: 50%;
}
button.generateBtn {
  background: #87c4d0;
  background: var(--UI_Box_Finished,#87c4d0);
  border: none;
  border-radius: 8px;
  color: #3A404F;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding: 12px 28px;
  position: fixed;
  bottom: 15px;
  z-index: 99;
  right: 50%;
}
.concerngenerateloader {
  width: 87%;
}
.default_loader_skelton {
  height: 15px;
  width: 100%;
  border-radius: 30px;
  margin: 9px 0;
}
.default_loader_skelton.half {
  width: 50%;
}
.concerngenerateloader_top {
  border-radius: 15px;
  background: var(--white-100, #FFF);
  box-shadow: 0px 4px 10px 0px rgba(172, 179, 196, 0.25);
  padding: 30px 50px;
  display: flex;
}
.concerngenerateloader_box {
  width: 100%;
}

.concerngenerateloader_middle {
  border-radius: 15px;
  background: var(--white-100, #FFF);
  box-shadow: 0px 4px 10px 0px rgba(172, 179, 196, 0.25);
  padding: 30px 50px;
  text-align: center;
  margin: 30px 0;
}
.concerngenerateloader_middle h3 {
  color: var(--Primary-Dark-Blue, #3A404F);
  text-align: center;
  font-family: Roboto;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 600;
  margin: 0 0 22px 0;
}
.concerngenerateloader_middle h5 {
  color: var(--Primary-Dark-Blue, #3A404F);
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
.concern-container-ouput {
  width: 85%;
  margin-top: 40px;
}
.concern-container-ouput-tabs {
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 4px 10px 0px rgba(172, 179, 196, 0.25);
  padding: 16px 35px 27px 35px;
  position: relative;
  display: flex;
  align-items: center;
}
.concern-container-ouput-tabs .nav-link {
  color: #ACB3C4;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  padding: 10px 21px;
  border-radius: 15px;
  position: relative;
}
.concern-container-ouput-tabs .nav-link img {
  margin-right: 7px;
  filter: opacity(0.5);
}
.concern-container-ouput-tabs .nav-link.active {
  background: #F1F1F1;
  color: #263B75;
  font-weight: 500;
  font-size: 22px;
}
.concern-container-ouput-tabs .nav-link.active img{
  filter: opacity(1);
}
.concern-container-ouput-tabs::before {
  content: "";
  position: absolute;
  bottom: 19px;
  left: 35px;
  right: 35px;
  background: rgba(172, 179, 196, 0.20);
  height: 1px;
}
.concern-container-ouput-tabs .nav-link.active::before{
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  background: #263B75;
  height: 3px;
}
ul.interviewList li {
  border-radius: 15px;
  background: var(--white-100, #FFF);
  box-shadow: 0px 4px 10px 0px rgba(172, 179, 196, 0.25);
  padding: 20px 25px;
  margin: 15px 0px;
}
ul.interviewList li h3 {
  color: #263B75;
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
}
ul.interviewList li p {
  color: #263B75;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27.153px;
}
ul.interviewList li button {
  border-radius: 12px;
  background: #E0EDF0;
  color: #3A404F;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  border: none;
  padding: 10px 15px;
  display: inline-block;
}
ul.interviewList li button img {
  margin-right: 6px;
}
/* ul.interviewList {
  height: calc(100vh - 300px);
  overflow-y: scroll;
  min-height: auto;
} */
.concern-l .common-left {
  justify-content: flex-start;
  padding-bottom: 50px;
  margin-left: 40px;
}
.concern-l .comonn-right {
  justify-content: flex-start;
}
.question-modal-top button {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #87C4D0;
  background: transparent;
  border: none;
  font-size: 22px;
}
.question-modal-top h4 {
  color: #263B75;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin: 11px 0 16px 0;
}
.question-modal-top h5 {
  color: #2F4787;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27.153px;
  margin: 0;
}
.question-modal .modal-body {
  padding: 0;
}
.question-modal-top {
  border-bottom: 2px solid rgba(172, 179, 196, 0.20);
  padding: 20px 30px;
}
.question-modal-middle {
  padding: 20px 30px;
  min-height: auto;
  max-height: 350px;
  overflow-y: scroll;
}
.question-modal-middle label {
  color: #263B75;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin: 0 0 6px 0;
}
.question-modal-middle p {
  color: #000;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.153px;
}
.question-modal-bottom {
  padding: 14px 30px;
  display: flex;
  justify-content: space-between;
}
.question-modal-bottom button {
  border-radius: 14.08px;
  background: #87C4D0;
  box-shadow: 0px 0px 7.04px 0px rgba(255, 255, 255, 0.32);
  color: var(--White, var(--white-100, #FFF));
  font-family: Poppins;
  font-size: 17.6px;
  font-style: normal;
  font-weight: 500;
  line-height: 24.64px;
  letter-spacing: -0.176px;
  border: none;
  display: inline-block;
  padding: 13px 21px;
}
button.suggestionBtn {
  color: #618DFF;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27.153px;
  text-decoration-line: underline;
  border: none;
  background: transparent;
  display: inline-block;
  margin-left: 10px;
}

button.concernsaveBtn {
  position: absolute;
  bottom: 12px;
  right: 15px;
  border-radius: 14.08px;
  background: #87C4D0;
  box-shadow: 0px 0px 7.04px 0px rgba(255, 255, 255, 0.32);
  border: none;
  padding: 6px 22px;
  color: #fff;
  cursor: pointer;
}
button.concernsaveBtn:disabled{
  background: #D0D2D9;
  pointer-events: none;
}
.question-modal-middle textarea.form-control {
  padding: 10px 10px 56px 10px;
}
.concern-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px 28px;
  color: var(--YSF-main-color, #3A404F);
  text-align: left;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  z-index: 9;
  background: rgb(247 249 251);
  background: linear-gradient(270deg, rgb(247 249 251) 49.3%, rgba(255, 255, 255, 1) 49.3%);
}
.concern-footer i.fa-solid.fa-arrow-left {
  margin-right: 20px;
}
.concern-footer a{
  color: #3A404F;
}
.concern-footer button {
  border: none;
  background: transparent;
  padding: 0;
  color: #3A404F;
}
ul.intoTab {
  justify-content: flex-end;
  border: none;
  margin: 10px 0 12px 0;
}
ul.intoTab .nav-link {
  color: var(--Blue-Grey, #ACB3C4);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  background: transparent;
}
ul.intoTab .nav-link.active {
  color: #87C4D0;
  font-weight: 600;
  background: transparent;
}
/* ul.intoduction_list {
  height: calc(100vh - 340px);
  overflow-y: scroll;
  min-height: auto;
} */
ul.intoduction_list li {
  border-radius: 15px;
  background: var(--white-100, #FFF);
  box-shadow: 0px 4px 10px 0px rgba(172, 179, 196, 0.25);
  padding: 20px 25px;
  margin-bottom: 15px;
}
.intro-top {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}
.intro-top h3 {
  color:  #2F4787;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin: 0;
}
.intro-top button {
  background: transparent;
  border: none;
  color: #87C4D0;
  text-decoration: underline;
  font-size: 15px;
}
ul.intoduction_list li p {
  color: #323336;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.153px;
}
.lft-number {
  position: absolute;
  left: -42px;
  top: 0;
  height: 116%;
  width: 2px;
  background: #87C4D0;
}
span.lft-number-top {
  top: -12px;
  left: -15px;
  width: 30px;
  height: 30px;
  background: #87C4D0;
  position: absolute;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
span.lft-number-bottom {
  bottom: -12px;
  left: -15px;
  width: 30px;
  height: 30px;
  background: #E6E6E6;
  position: absolute;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.concern-box.firstTime {
  opacity: 0.3;
}
.slider-animate-mid img {
  margin: 0 auto;
  width: auto;
  height: 200px;
}
.concern-l .pageHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.concern-l .pageHeading button {
  border: none;
  padding: 0;
  background: transparent;
}
.c-box {
  transition: .3s ease-in-out;
  height: calc(100vh - 376px);
  overflow-y: scroll;
  padding: 15px 9px 15px 0;
}
.hide.c-box {
  transition: .3s ease-in-out;
  display: none;
}
p.error-msg {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}
button.themeBtn[disabled] {
  opacity: 0.5;
  pointer-events: none;
}
button.next-button.selectedBtn[disabled]{
  background: #D0D2D9;
  opacity: 0.5;
}
.output-view-body.step-jobsview {
  max-height: calc(65vh);
  overflow-y: scroll;
}

.btnloader {
  position: absolute;
  width: 18px;
  height: 18px;
  border: 2px solid #3A404F;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  top: 15px;
  margin-left: 4px;
}

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 
  .common-panel.home-page {
    background: #F7F9FB;
    min-height: 100vh;
}
.header .container-fluid {
  padding: 0 35px;
}
.home-container {
  width: 90%;
  margin: 0 auto;
}
.logout-modal ul {
  display: flex;
  justify-content: center;
  gap: 7px;
  margin-top: 27px;
}
.header-dropdown button.signOutBtn {
  width: auto;
  height: auto;
  border-radius: 0;
  background: transparent;
  color: #3A404F;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
  text-decoration: none;
  padding: 7px 15px;
}
.home-tabs table tr:hover {
  cursor: pointer;
}
.linkdinUrl button[disabled] {
  opacity: 0.5;
  pointer-events: none;
}
.uploadloader {
  width: 100%;
  height: 12px;
  display: inline-block;
  background-color: #FFF;
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.25) 50%, rgba(0, 0, 0, 0.25) 75%, transparent 75%, transparent);
  font-size: 30px;
  background-size: 1em 1em;
  box-sizing: border-box;
  animation: barStripe 1s linear infinite;
}

@keyframes barStripe {
  0% {
    background-position: 1em 0;
  }
  100% {
    background-position: 0 0;
  }
}
.concerndefaultloader {
  width: 75%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.onboard-page {
  background: #f1f1f1;
  min-height: 100vh;
  padding-top: 57px;
}
.onboard-container {
  display: flex;
  gap: 50px;
  width: 100%;
}
.onboard-container-small_lft {
  flex: 10% 1;
  border-radius: 0 50px 50px 0;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.onboard-container-small_rht {
  flex: 10%;
  border-radius: 50px 0 0 50px;
  background:  #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.onboard-container-big {
  flex: 80% 1;
  border-radius: 50px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 190px 0 124px 0;
  position: relative;
}
.onboard-container-outer {
  height: calc(100vh - 70px);
  display: flex;
  width: 100%;
  align-items: center;
}
.onboard_slider {
  padding: 20px 26%;
  text-align: center;
}
.onboard_slider h4 {
  color: #3A404F;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 17px 0 8px 0;
}
.onboard_slider p {
  color: #859AC3;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
}
.onboard_slider .nextBtn {
  border-radius: 24px;
  background: #87C4D0;
  border: none;
  color: #323336;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  padding: 17px 55px;
  display: block;
  margin: 23px auto 0 auto;
  transition: .3s ease-in-out;
}
.onboard_slider button i{
  margin-left: 10px;
}
.onboard_slider img {
  height: 300px;
}
button.prevBtn {
  position: absolute;
  top: 30px;
  left: 30px;
  border: none;
  background: transparent;
  color: var(--Secondary-Blue-Gray, #ACB3C4);
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.slider_dots ul span {
  width: 14px;
  height: 14px;
  background: #D9D9D9;
  display: block;
  border-radius: 50%;
}
.slider_dots ul {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.slider_dots {
  position: absolute;
  bottom: -35px;
  left: 0;
  right: 0;
}
.slider_dots ul li.active span {
  background: #87C4D0;
  width: 46px;
  border-radius: 8px;
}
.concerndefaultloader.Toggle {
  position: relative;
  top: 0;
  left: 0;
  transform: inherit;
}
button.saveBtn[disabled] {
  opacity: 0.5;
}
.continueBtn {
  color: #859AC3;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  background: transparent;
  width: 100%;
  padding: 20px 9px;
  transition: .4s ease-in-out;
  border: 1px dashed transparent;
  position: relative;
  border-radius: 5px;
  display: block;
}
.continueBtn:hover{
  transition: .4s ease-in-out;
  border: 1px dashed transparent;

border: 1px dashed #ACDBEF;
background: #F4FAFB; 
}
.vh-class {
  min-height: calc(100vh - 116px);
  margin: 0;
}
.cover-letter-sep {
  border-radius: 15px;
  background: var(--white-100, #FFF);
  box-shadow: 0px 4px 10px 0px rgba(172, 179, 196, 0.25);
  padding: 15px 45px;
  margin: 10px 0;
}
.cover-letter-sep h4 {
  color: #87C4D0;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  margin: 0 0 12px 0;
}
.cover-letter-sep p {
  color: #323336;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  white-space: normal !important;
}
/* .cover-letter {
  margin-top: 10px;
  min-height: auto;
  max-height: 62vh;
  overflow-y: scroll;
} */
.comonn-right.output {
  justify-content: flex-start;
}
img.logo {
  width: 173px;
}
.output-view-body.step-oneview ul li:hover {
  background: #E0EDF0;
  transition: .3s ease-in-out;
}
.des-result ul li {
  color: #3A404F;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.153px;
  position: relative;
  margin-bottom: 10px;
  padding: 0 0 0 19px;
}
.des-result ul li::before {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  background: #3A404F;
  border-radius: 50%;
  left: 0;
  top: 10px;
}
.des-result ul li:hover {
  background: transparent !important;
}

.Added_concern .form-control {
  border-radius: 8px;
  border: 1px solid #87C4D0;
  background: #FAFAFA;
  font-size: 20px;
  color: #3A404F;
  padding: 14px 15px;
  margin-bottom: 13px;
}
.Added_concern .form-control:focus{
  box-shadow: none;
}
.Added_concern .form-control::placeholder{
  color: #B6BACA;
}
ul.toggle_list_btn {
  display: flex;
  align-items: center;
}
.concern-l .pageHeading  button.backConcernBtn {
  border-radius: 8px;
  background:  #E0EDF0;
  color:  #3A404F;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 136.173%;
  letter-spacing: -0.08px;
  display: block;
  padding: 12px 21px;
}
.concern-l .pageHeading  button.tabConcernBtn {
  border-radius: 8px;
  background:  #87C4D0;
  color:  #3A404F;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 136.173%;
  letter-spacing: -0.08px;
  display: block;
  padding: 12px 21px;
  margin-left: 10px;
}
button.generateBtn[disabled] {
  opacity: 0.5;
}
.hover-btn {
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: .4s ease-in-out;
}
/* .hover-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #3A404F;
  width: 0;
  bottom: 0;
  z-index: -1;
  transition: .4s ease-in-out;
} */
/* .hover-btn:hover::before{
  width: 100%;
  transition: .4s ease-in-out;
} */
.hover-btn:hover{
  color: #fff;
  background: #3A404F;
}
.jobBtn::before{
  background: #87C4D0;
}
.go_btn {
  /* width: 95%; */
  text-align: right;
  /* margin-top: 23px; */
  position: fixed;
  z-index: 99;
  bottom: 15px;
  right: 3.5%;
}
/* .coverlettrBtn {
  border-radius: 8px;
  background: #E0EDF0;
  color: #3A404F;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.08px;
  display: inline-block;
  padding: 14px 22px;
  border: none;
  position: fixed;
  bottom: 3px;
  right: 3.5%;
  z-index: 99;
} */
.custom-checkbox {
  display: flex;
  align-items: center;
  gap: 11px;
  position: relative;
}
.custom-checkbox span {
  color: #87C4D0;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
}

.jobpostoutput-view {
  display: flex;
  gap: 10px;
  width: 95%;
}
.part-view {
  flex: 1;
  background: #FFF;
  box-shadow: 0px 4px 10px 0px rgba(172, 179, 196, 0.25);
  border-radius: 15px;
  overflow: hidden;
}
.part-view h4 {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #fff;
  margin: 0;
}
.part-view .output-view-top {
  border-radius: 15px 15px 0 0;
}
.output-view-body.diff-output {
  background: #FFF;
  box-shadow: 0px 4px 10px 0px rgba(172, 179, 196, 0.25);
  border-radius: 15px;
  overflow: hidden;
  width: 95%;
  max-height: 65vh;
  overflow-y: scroll;
  min-height: auto;
}
.question-modal-bottom button[disabled] {
  background: #ccc;
}
.tryBtn {
  border: 1px solid #E27454;
  border-radius: 8px;
  color: #3A404F;
  display: inline-block;
  padding: 10px 10px;
  font-size: 14px;
  margin-left: 25px;
  margin-top: 11px;
  cursor: pointer;
}
pre {
  white-space: pre-wrap; /* Preserve line breaks */
  font-family: Roboto; /* Use a monospaced font */
  padding: 1px; /* Add padding for better readability */ 
  border-radius: 0px; /* Rounded corners */
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
}
img.crown_gif {
  position: absolute;
  top: 0;
  right: 0;
  width: 83px;
}
.cv-replace-sec {
  border-radius: 15px;
  border: 1px dashed #D0D2D9;
  background: #F6FAFB;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
  justify-content: space-between;
  position: relative;
}
.upload-container {
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.upload-container span {
  color: var(--7583-a-5, #7583A5);
  text-align: center;
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.153px;
  width: 60%;
  text-align: left;
}
.upload-file {
  position: relative;
  cursor: pointer;
}
.upload-file label {
  border-radius: 8px;
  border: 1px solid var(--Secondary-Blue-Teal, #87C4D0);
  background: var(--Tertiary-Pale-Blue, #E7F3F6);
  color: var(--Primary-Dark-Blue, #3A404F);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline-flex;
  padding: 14px 14px;
  cursor: pointer;
  align-items: center;
  gap: 12px;
}
.upload-file input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}
.cv-replace-sec button {
  border-radius: 8px;
  background: #87C4D0;
  border: none;
  padding: 10px 28px;
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 27.153px;
  cursor: pointer;
}
.cv-replace-sec button:disabled{
  background: #D0D2D9;
  color: #fff;
  pointer-events: none;
}
.upload-container-right {
  display: flex;
  gap: 14px;
  justify-content: flex-end;
  width: 58%;
}
.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .5;
  &:before {
    content: '';  
    background: linear-gradient(to right, transparent, #2F2E41, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;

    padding: 0 .5em;
    line-height: 1.5em;    
    color: #2F2E41;
    background-color: #fcfcfa;
  }
}

h4.specialTxt {
  text-align: center;
  color: #2F2E41;
  font-family: Poppins;
  font-size: 18.333px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.367px;
  position: relative;
  margin: 0 0 28px 0;
}
h4.specialTxt::before {
  content: "";
  position: absolute;
  left: 0;
  top: 11px;
  height: 4px;
  background: rgba(172, 179, 196, 0.30);
  width: 9%;
  border-radius: 3px 0 0 3px;
}
h4.specialTxt::after {
  content: "";
  position: absolute;
  right: 0;
  top: 11px;
  height: 4px;
  background: rgba(172, 179, 196, 0.30);
  width: 9%;
  border-radius: 0px 3px 3px 0px;
}
.checkbox-wrapper {
  width: 70%;
  display: flex;
  align-items: center;
  gap:10px;
}
.checkbox-wrapper span {
  color: #5E9CB1;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.32px;
}
.linkdinUrl  button.clipboard_icon {
  top: 1px;
  bottom: 1px;
  right: 1px;
  height: 97%;
  padding: 4px 12px;
}
img.LoaderIcon {
  animation: rotation infinite .5s linear;
}

@keyframes rotation {
  from {
      transform: rotate(0deg);
  }

  to {
      transform: rotate(360deg);
  }
}
.uploadLoader {
  width: 92%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.uploadLoader h4 {
  color: var(--7583-a-5, #7583A5);
  text-align: center;
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 27.153px;
  margin: 0;
}
.uploadLoader button {
  background: transparent;
  border: none;
  padding: 0;
}
.success-upload {
  width: 90%;
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.success-upload h4 {
  color: var(--7583-a-5, #7583A5);
  text-align: center;
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 27.153px;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 6px;
  width: 65%;
  text-align: left;
}
.success-upload > div {
  width: 35%;
  display: flex;
  justify-content: flex-end;
}
button.DeleteBrn {
  border: none;
  background: transparent;
  margin-left: 11px;
}
button.replaceCVBtn {
  border-radius: 8px;
  border: 1px solid #87C4D0;
  background: #E7F3F6;
  color: #3A404F;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 9px 14px;
}
.output-view.for-jobpost {
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 4px 10px 0px rgba(172, 179, 196, 0.25);
  width: 90%;
  /* overflow: hidden;
  height: 77%; */
  margin-top: 1%;
}
.output-view.for-jobpost .output-view-body {
  padding: 15px 36px;
  height: calc(100vh - 200px);
  overflow-y: scroll;
}
.comonn-right.jobpostview {
  background: #F7F9FB;
  height: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  position: relative;
}
.cover-letter-sep.fulllength {
  display: flex;
  gap: 15px;
}
.fulllength .left-side {
  width: 20%;
}
.fulllength .right-side {
  width: 88%;
}
.my_ans h4 {
  color: #2F4787;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}
ul.interviewList li .my_ans p {
  color: #3A404F;
  font-family: Roboto;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 27.153px;
}
button.collapseBtn {
  border-radius: 8px;
  background: #E0EDF0;
  color: #3A404F;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.08px;
  display: inline-block;
  padding: 11px 17px;
  border: none;
  position: fixed;
  bottom: 15px;
  left: auto;
  right: 50%;
  z-index: 999;
  margin: 0 auto;
  text-align: center;
}
input#LinkdinUrl {
  padding: 14px 56px 14px 15px !important;
}
p.errorurlMsg {
  color: #E27454;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  margin: 0;
}
p.errorurlMsg button {
  border: none;
  background: transparent;
  color: #E27454;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  text-decoration-line: underline;
}
ul.jubpost-bottom-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
button.outlineBtn {
  background: var(--white-100, #FFF);
  color: var(--Secondary-Blue-Teal, #87C4D0);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  border-radius: 8px;
  padding: 10px 26px;
  border: 1px solid #87C4D0;
}
input.form-control.error {
  box-shadow: inset 0 0 5px #ff7a7a;
  border: 1px solid #ff7a7a;
}


.noresultFound {
  background: #FFFFFF;
  border: 1px solid #E27454;
  box-shadow: 0px 2px 26px rgba(226, 116, 84, 0.1);
  border-radius: 6px;
  padding: 10px;
  position: relative;
  margin-bottom: 10px; 
  width: 49%;
  margin: 0 auto;
 
}

  .noresultFound img {
      position: absolute;
      left: 9px;
      top: 7px;
  }

.noresultFoundInner {
  padding: 0 27px;
  text-align: left;
}

  .noresultFoundInner h4 {
      color: #E27454;
      font-size: 15px;
      font-weight: 600;
  }

  .noresultFoundInner p {
      margin: 0;
      color: #3A404F;
      font-size: 13px;
      line-height: 1.2;
  }

buttton.closeError {
  position: absolute;
  right: 10px;
  top: 9px;
  cursor: pointer;
}
.tryBtn {
  border: 1px solid #E27454;
  border-radius: 8px;
  color: #3A404F;
  display: inline-block;
  padding: 10px 10px;
  font-size: 14px;
  margin-left: 25px;
  margin-top: 11px;
  cursor: pointer;
  background: #fff;
}
.tryBtn:hover{
  background: #E27454;
  color: #fff;
}
button.replaceCVBtn:hover{
background: #87C4D0;
}

.cover-letter-sep h4 button {
  border: none;
  background: transparent;
}
/* .disabled {
  pointer-events: none;
} */
.step-box_img {
  height: 158px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 8px;
}
.step-box_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  transition: .3s ease-in-out;
}
.step-box h4 {
  color: #3A404F;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  margin: 8px 0 0 0;
}
.step-box:hover img{
  transform: scale(1.1);
  transition: .3s ease-in-out;
}
.big-modal .modal-dialog {
  max-width: 700px;
}
.big_img img {
  width: 100%;
}
.big-modal .modal-dialog .modal-content {
  background: transparent;
  border: none;
}
button.close_btn {
  position: absolute;
  right: -44px;
  top: -22px;
  background: transparent;
  border: none;
}
.big-modal .modal-dialog h4 {
  color: var(--white-100, #FFF);
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
  margin: 11px 0 0 0;
}
h5.wrongposturltext {
  color: #E27454;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
h5.wrongposturltext button {
  color: #E27454;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
  border: none;
  background: transparent;
  padding: 0;
}
.jobsinput-field.job-dexcription-field {
  align-items: flex-start;
}
button.backBtn {
  border-radius: 8px;
  border: 2px solid var(--Secondary-Blue-Teal, #87C4D0);
  background: var(--Pale-Blue, #E7F3F6);
  display: inline-block;
  padding: 10px 22px;
  margin-right: 8px;
}

.job-post-tab-button button {
  border: none;
  font-size: 18px;
  display: block;
  padding: 15px 19px;
  background: #e7f3f6;
}
.job-post-tab-button {
  display: flex;
}
.job-post-tab-button button.active::before {
  width: 100%;
  transition: .4s ease-in-out;
}
.job-post-tab-button button.active {
  color: #fff;
  background: #3A404F;
}
.upload-container h7 {
  font-size: 20px;
  display: flex;
  align-items: center;
  gap: 9px;
  color: #7583A5;
  font-weight: 600;
}
.not-toogle-answear .my_ans h4 {
  color: #2F4787;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}
.not-toogle-answear .my_ans p {
  color: #000;
  font-family: Roboto;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}
h5.subscriptionText {
  margin: 0;
  font-size: 14px;
  color: #bb0e0e;
}
h5.subscriptionText a {
  background: #263b75;
  color: #fff;
  display: inline-block;
  padding: 3px 6px;
  border-radius: 3px;
  font-size: 14px;
  border:none;
}
h5.subscriptionText button {
  background: #263b75;
  color: #fff;
  display: inline-block;
  padding: 3px 6px;
  border-radius: 3px;
  font-size: 14px;
  border:none;
}
.subscription-box {
  border-radius: 24px;
  background: #F4FAFB;
  padding: 25px;
}
.subscription-container {
  border-radius: 16px;
  background: var(--white-100, #FFF);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 30px;
  max-width: 100%;
  position: relative;
}
.subscription-container h3 {
  color: #2F2E41;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.subscription-container h6 {
  color: #323336;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.subscription-box {
  border-radius: 24px;
  background: #E0EDF0;
  padding: 25px;
  transition: .3s ease-in-out;
  position: relative;
}
.subscription-box:hover, .subscription-box.active {
  background: #2F2E41;
  transition: .3s ease-in-out;
  margin-top: -20px;
}
.subscription-box:hover h4, .subscription-box.active h4{
  color: #fff;
}
.subscription-box:hover p, .subscription-box.active p{
  color: #F3F1EE; 
}
.subscription-box:hover span, .subscription-box.active span{
  color: #F3F1EE; 
}
.subscription-box:hover span sub, .subscription-box.active span sub{
  color: #F3F1EE; 
}
.subscription-box:hover .TrynowBtn, .subscription-box.active .TrynowBtn{
  border-radius: 8px;
  border: 1px solid #263B75;
  background: var(--Secondary-Blue-Teal, #87C4D0);
}
.subscription-box.active {
  pointer-events: none;
}


.subscription-box h4 {
  margin: 0 0 27px 0;
  color: #208CA1;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.subscription-container  p {
  text-align: left;
  margin: 0 0 68px 0;
  color: #2F2E41;
  font-family: 'Roboto';
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.subscription-box span {
  display: block;
  opacity: 0.96;
  color: #2F2E41;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 22px 0;
}
.subscription-box span sub {
  color: #2F2E41;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.subscription-box .TrynowBtn {
  color: var(--Primary-Dark-Blue, #3A404F);
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: var(--white-100, #FFF);
  border: 2px solid #87C4D0;
  border-radius: 5px;
  padding: 14px 43px;
  display: block;
  width: 100%;
}
.subscription-box label {
  position: absolute;
  top: 17px;
  right: 21px;
  margin: 0;
  color: #323336;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 4px;
  background: var(--white-100, #FFF);
  padding: 5px 10px;
}
.coupon-form input.form-control {
  padding: 13px 15px;
  text-transform: uppercase;
}
.coupon-form .ApplynowBtn {
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background: #263b75;
  color: #fff;
  font-size: 15px;
  display: block;
  padding: 10px 10px;
  border-radius: 4px;
}
.coupon-form {
  position: relative;
  margin-bottom: 17px;
}
.language-dropdown .dropdown-menu li {
  color: #3A404F;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 8px 12px;
  cursor: pointer;
}
.language-dropdown .dropdown-menu h5 {
  color: #3A404F;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1.62px;
  text-transform: uppercase;
  margin: 0;
}

.language-dropdown .dropdown-menu li {
  color: #3A404F;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  padding: 8px 12px;
  cursor: pointer;
}
.language-dropdown .dropdown-menu li img {
  margin-right: 3px;
}
.l-note {
  padding: 8px 12px;
  border-top: 1px solid #ccc;
}
.l-note p {
  text-align: left;
  color: #3A404F;
  font-size: 15px;
  line-height: 1.4;
  margin: 6px 0 19px 0;
}
.language-dropdown .dropdown-menu li:hover, .language-dropdown .dropdown-menu li.active {
  background: #E7F3F6;
}
.success-box h3 {
  color: #42ADA2;
  text-align: left;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 4px 0 12px 0;
}
.success-box p {
  color: #263B75;
  text-align: left;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 37px 0;
}
.alert-modal{
  padding: 60px 30px;
}
.alert-modal h3{
  color: #F5B21A;
text-align: center;
font-family: Poppins;
font-size: 40px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.alert-modal p{
  color: #263B75;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.alert-modal .modal-dialog {
  max-width: 922px !important;
}
.alert-modal .modal-dialog .modal-body {
  padding: 70px 30px;
}
.BtnStyle_two {
  border-radius: 8px;
  border: 1px solid var(--Secondary-Blue-Teal, #87C4D0);
  background: var(--Secondary-Blue-Teal, #87C4D0);
  color: var(--Primary-Dark-Blue, #3A404F);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline-block;
  padding: 14px 72px;
}
.subscription-trail h3 {
  color: #F5B21A;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.subscription-trail{
  justify-content: center;
  align-items: center;
  display: flex;
}

.success-box{
  justify-content: center;
  align-items: center;
  display: flex;
}
.common-panel.home-page.subscription-trail {
  background: rgba(0,0,0,0.6);
  z-index: 99999;
  position: relative;
}
.subscription-modal .modal-dialog {
  max-width: 1345px;
}
.subscription-modal .modal-dialog .modal-body {
  padding: 190px 130px;
}
.subscription-modal h3 {
  color: #87C4D0;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 12px 0 14px 0;
}
.subscription-modal p {
  color: #263B75;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 42px 0;
}
.header-right .language-dropdown .dropdown-menu {
  border-radius: 7px;
  background: var(--white-100, #FFF);
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25);
  min-width: 260px;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 32px;
  top: 58px;
  display: block;
  width: 344px;
}
a.backBttn {
  position: absolute;
  top: 30px;
  left: 30px;
  color: var(--Secondary-Blue-Gray, #ACB3C4);
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.subscription-modal.language_setup_modal .modal-body {
  padding: 100px 190px;
}
.subscription-modal .modal-dialog .modal-body h4 {
  color: #3F3D56;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 21px 0;
}
.subscription-modal .modal-dialog .modal-body p {
  color: #1B1B29;
  text-align: center;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin: 0 0 57px 0;
}
.subscription-modal .modal-dialog .modal-body ul {
  display: flex;
  justify-content: center;
  gap: 12px;
  align-items: center;
}
.subscription-modal .modal-dialog .modal-body ul button {
  background: transparent;
  width: 158px;
  height: 158px;
  border-radius: 50%;
  border: 7px solid transparent;
  display: block;
  padding: 10px;
}
.subscription-modal .modal-dialog .modal-body ul button img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.subscription-modal .modal-dialog .modal-body ul li.active button {
  border: 7px solid #87C4D0;
}
.language_setup_modal .modal-body h6 {
  color: #1B1B29;
  text-align: center;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
ul.success-box_btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.BtnStyle_one {
  border-radius: 8px;
  border: 1px solid var(--Secondary-Blue-Teal, #E27454);
  background: var(--Secondary-Blue-Teal, #E27454);
  color: var(--Primary-Dark-Blue, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline-block;
  padding: 14px 50px;
}
.before-go p {
  margin: 0 0 28px 0;
  text-align: left;
}
.radio-group-list {
  text-align: left;
}
.radio-group-list li {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 18px;
  cursor: pointer;
}
.radio-group-list li label {
  color: #263B75;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.radio-group-list span {
  width: 25px;
  height: 25px;
  display: block;
  border: 2px solid #87C4D0;
  border-radius: 50%;
  position: relative;
}
.radio-group-list li.active span::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  background: #87C4D0;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
.before-go textarea.form-control {
  border-radius: 13px;
  border: 2px solid #CFE7EC;
}
.language-information h4 {
  color: #0052B5;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
}
.language-information li {
  color: #000;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 0 7px 15px!important;
  position: relative;
}
.language-information li:before {
  background: #9d9d9d;
  border-radius: 50%!important;
  content: "";
  display: block;
  height: 5px!important;
  left: 0;
  position: absolute;
  top: 8px;
  width: 5px!important;
}
.language-information {
  margin-bottom: 20px;
}
.wallet-container {
  width: 1320px;
  margin: 0 auto;
  padding: 50px 0;
  max-width: 100%;
}
.wallet {
  display: flex;
  align-items: center;
  gap: 30px;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 15px;
}
.wallet h5 {
  font-size: 32px;
  color: #666;
}
.wallet b {
  font-size: 38px;
}
.refer-url {
  position: relative;
  margin: 0 0 21px 0;
  display: flex;
  align-items: center;
  gap: 9px;
}
.refer-url input.form-control {
  padding: 10px 15px;
  width: 55%;
  background: #fff;
  border: 2px solid #87C4D0;
  color: var(--Primary-Dark-Blue, #3A404F);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.refer-url-right {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 42%;
}
.refer-url button {
  padding: 11px 9px;
  border-radius: 6.384px;
  border: 0.798px solid var(--Secondary-Blue-Teal, #87C4D0);
  background: var(--Secondary-Blue-Teal, #87C4D0);
  color: var(--Primary-Dark-Blue, #3A404F);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 190px;
}
.refer-container {
  background: #fff;
  border-radius: 15px;
  padding: 30px;
}
.refer-container h3 {
  color: #2F2E41;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.refer-container p {
  color: #323336;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 37px 0;
}
.refer-container h4 {
  color: #208CA1;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 11px 0;
}
.get-box {
  border: 1px solid #e7e4e4;
  padding: 20px;
  border-radius: 15px;
  background: #fff;
  min-height: 177px;
}
.get-box_list {
  display: flex;
  align-items: flex-start;
  gap: 11px;
  margin-bottom: 10px;
}
.get-box_list img {
  position: relative;
  top: 3px;
}
.get-box_list h5 {
  margin: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.5;
}
.invoice-sec {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
}
.invoice-title {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 45px;
}
.invoice-title img {
  width: 101px;
}
.invoice-title h3 {
  color: #222;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 48px;
  margin: 0;
}
.invoice-title h4 {
  margin: 0;
  color: #666;
  font-weight: 400;
  font-size: 20px;
}

/* CSS file or style block */
/* Example Dimensions */
.fixed-size-modal .modal-dialog {
  width: 800px;
  height: 800px;
}

.fixed-size-modal .modal-content {
  height: 100%;width: 100%;
}

.fixed-size-modal .modal-body {
  overflow-y: auto;
}
.table-responsive.home-tabs {
  max-height: 400px;
  overflow-y: scroll;
  min-height: auto;
}
.landing-story-box.refernow-box {
  background: #E5EBEC;
  border-left: 10px solid #263B75;
  padding: 60px 30px;
}
.landing-story-box.refernow-box h5 {
  color: #3A404F;
}
.landing-story-box.refernow-box p {
  color: #3A404F;
}
.landing-story-box.refernow-box a {
  display: flex;
  padding: 12px 23px;
  color: var(--Primary-Dark-Blue, #3A404F);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-items: center;
  gap: 9px;
  border-radius: 8px;
  border: 2px solid var(--Secondary-Blue-Teal, #87C4D0);
  background: var(--white-100, #FFF);
}
.overview-card ul {
  display: flex;
  justify-content: space-between;
}
.overview-card ul li {
  text-align: center;
}
.overview-card ul li b {
  display: block;
  color: #3A404F;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.2px;
}
.overview-card ul li span {
  color: #3A404F;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.2px;
}
.overview-card h5 {
  color: var(--YSF-main-color, #3A404F);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin: 0;
}
.refer_code {
  margin-bottom: 30px;
}
.checkboxBtn {
  border: none;
  background: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  color: var(--Primary-Dark-Blue, #3A404F);
  font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.153px;
}
.refer-input {
  position: relative;
  width: 272px;
  margin: 22px auto 0 auto;
}
.refer-input input {
  border: 1px solid #87C4D0;
  color: var(--Gray-900, #001246);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 12px 30px 12px 15px;
  width: 100%;
}
.refer-input button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: #87C4D0;
  border: none;
  padding: 0 17px;
}
.refer-input input:focus{
  border: 1px solid #87C4D0;
  outline: none;
}
img.confirm_check {
  position: absolute;
  right: 13px;
  top: 39%;
  bottom: 0;
}
.left-sidebar ul li a {
  color: var(--3-a-404-f, #3A404F);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-block;
  padding: 6px 15px;
}
.left-sidebar ul li a img {
  margin-right: 5px;
}
.left-sidebar ul li a.active {
  background: rgba(135, 196, 208, 0.50);
  border-radius: 30px;
  color: #091E42;
  font-weight: 500;
}
.code-ref-view label {
  background: var(--white-100, #FFF);
  border: 2px solid #87C4D0;
  color: var(--Primary-Dark-Blue, #3A404F);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: block;
  padding: 9px 25px;
  border-radius: 5px;
  margin: 0;
}
.code-ref-view button {
  padding: 11px 19px;
  border-radius: 6.384px;
  border: 0.798px solid var(--Secondary-Blue-Teal, #87C4D0);
  background: var(--Secondary-Blue-Teal, #87C4D0);
  color: var(--Primary-Dark-Blue, #3A404F);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.code-ref-view {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 0 27px 0;
}
.invite-btn {
  display: flex;
  gap: 10px;
}
.invite-btn button {
  border: navajowhite;
  padding: 0;
  background: transparent;
}
.ref-right {
  border-radius: 24px;
  background: #F1F1F1;
  padding: 15px;
}
.ref-right h6 {
  color: #357A88;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.refer-container h5 {
  color: #18A8B2;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.subscription-modal .modal-dialog .confirm-modal-content h4 {
  color: var(--Title, #18A8B2);
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
}
.subscription-modal .modal-dialog .confirm-modal-content p {
  color: #263B75;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin: 0 0 0 0;
}
.reedem-lft h5 span {
  background: #E0EDF0;
  color: #3A404F;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline-block;
  padding: 3px 15px;
}
.reedem-lft h5 {
  color: #2F2E41;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  margin: 0 0 18px 0;
}
.reedem-lft h6 {
  color: #323336;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin: 0 0 17px 0;
}
.reedem-lft .claimNow {
  display: flex;
  border-radius: 6.384px;
  border: 0.798px solid var(--Secondary-Blue-Teal, #87C4D0);
  background: var(--Secondary-Blue-Teal, #87C4D0);
  color: var(--Primary-Dark-Blue, #3A404F);
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-items: center;
  gap: 11px;
  padding: 17px 22px;
}
.reedem-lft  .refer-input {
  width: 280px;
  margin: 22px 0 0 0;
}
.reedem-rht h3 {
  color: #2F2E41;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
}
.box-border {
  border-radius: 16px;
  border: 1px solid #87C4D0;
  padding: 20px;
  min-height: 288px;
}
.table-style table {
  width: 100%;
}
.table-style table th {
  color: var(--Primary-Dark-Blue, #3A404F);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 9px 5px;
}
.table-style table td {
  color: var(--3-a-404-f, #3A404F);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 9px 5px;
}
.billing-sec {
  border-radius: 16px;
  background: var(--white-100, #FFF);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 30px;
}
.billing-sec h3 {
  color: #2F2E41;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 33px 0;
}
.sec-list {
  border-bottom: 1px solid #E6E6E6;
  display: flex;
  padding: 20px 0;
}
.sec-list h4 {
  color: #2F2E41;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
  width: 25%;
}
.sec-list-right {
  width: 75%;
}
.sec-list-right ul {
  display: flex;
  justify-content: space-between;
}
.sec-list-right ul li {
  text-align: left;
}
.sec-list-right ul li b {
  display: block;
  letter-spacing: 0.2px;
  color: var(--Primary-Dark-Blue, #3A404F);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 8px 0;
}
.sec-list-right ul li span {
  color: #3A404F;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.2px;
}
.sec-list-right h5 {
  color: var(--YSF-main-color, #3A404F);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
}
.sec-list-right.paymentchange {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sec-list-right.paymentchange button {
  color: #3A404F;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 6.371px;
  border: 1.593px solid #87C4D0;
  background: var(--white-100, #FFF);
  display: block;
  padding: 14px 19px;
}
.table-style table td a {
  color: var(--3-a-404-f, #3A404F);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.issubscribe h5 {
  color: #2F2E41;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 22px 0;
}
.issubscribe h5 span {
  display: inline-block;
  background: #E0EDF0;
  color: #3A404F;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 5px 29px;
  margin-left: 7px;
}
.issubscribe p {
  color: #323336;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin: 0;
}
.isnotsubscribe {
  margin-bottom: 60px;
}
.issubscribe {
  margin-bottom: 60px;
}
.issubscribe p a {
  opacity: 0.96;
  color: #208CA1;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}
.header-right .dropdown-menu .dropdown-item a {
  padding: 0;
}
.giftunlockscreen {
  background: rgba(0,0,0,0.8);
  z-index: 999;
  position: relative;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.giftunlockscreen-inner {
  border-radius: 16px;
  background: var(--white-100, #FFF);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 80px 70px;
}
.giftunlockscreen-inner h4 {
  color: var(--Title, #18A8B2);
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.giftunlockscreen-inner p {
  color: #263B75;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.loader.pageloader {
  box-shadow: none;
  width: 100%;
}
.loader.pageloader .card__skeleton {
  background-image: linear-gradient(to right, rgb(237, 237, 237), rgb(241, 255, 255));
}
.unlock-modal  .confirm-modal-content {
  text-align: center;
  padding: 43px 30px;
}
.subscription-box.active button.TrynowBtn {
  border-radius: 8px;
  border: 2px solid #D0D2D9;
  background: var(--white-100, #FFF);
  color: var(--Secondary-Blue-Gray, #ACB3C4);
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.subscription-innercontainer {
  padding-left: 159px;
}
.nodata-list {
  background: var(--white-100, #FFF);
  padding: 30px;
  text-align: center;
  min-height: 400px;
}
.nodata-list h3 {
  color: #2F2E41;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 45px 0;
}
.plan-bulet {
    list-style-type: disc;
    margin-top: 10px;
    list-style-position: inside;
}
.plan-bulet li {
  list-style-type: disc;
  color: #263B75;
  font-size: 15px;
  font-weight: 600;
}
.BtnStyle_three {
  border-radius: 8px;
  border: 1px solid var(--Secondary-Blue-Teal, #87C4D0);
  background: var(--Secondary-Blue-Teal, #87C4D0);
  color: var(--Primary-Dark-Blue, #3A404F);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline-block;
  padding: 14px 40px;
}
.small-custom-modal .modal-dialog {
  max-width: 200px; /* Adjust the width as needed */
  max-height: 100px; 
}
.flashbttn{
  -webkit-animation: flash linear 2s infinite;
  animation: flash linear 2s infinite;
}
@-webkit-keyframes flash {
  0% { opacity: 1; }
  50% { opacity: .3; }
  100% { opacity: 1; }
}
@keyframes flash {
  0% { opacity: 1; }
  50% { opacity: .3; }
  100% { opacity: 1; }
}
.custom-toggle-menu {
  position: absolute;
  top: 59px;
  right: 15px;
  z-index: 99;
  width: 193px;
}
.custom-toggle-menu li button {
  background: #E0EDF0;
  color: #263B75;
  display: flex;
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  gap: 10px;
  padding: 10px 10px;
  border-radius: 0;
}
.custom-toggle-menu li {
  margin-bottom: 3px;
}
button.custom-toggle.active {
  border-radius: 8px 8px 0 0;
}
.custom-toggle-menu li:last-child button{
  border-radius:  0 0 8px 8px;
}
.custom-toggle-menu li button:hover{
  background: #3A404F;
  color: #fff;
}
.custom-toggle-menu li button:hover img{
  filter: brightness(0) invert(1);
}
.\"editable-container {
  white-space: normal !important;
  color: #3A404F;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.153px;
}
.float-bttn button {
  color: #3A404F;
  font-size: 16px;
  font-weight: 700;
  font-family: Poppins;
  border: none;
  background: #87C4D0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px 17px;
}
.float-bttn {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  /* margin-top: 15px; */
  position: fixed;
  bottom: 15px;
  right: 3.5%;
  z-index: 999;
}
.float-bttn button:hover {
  background: #3a404f;
  color: #fff;
}
.float-bttn button:hover img{
  filter: brightness(0) invert(1);
}
/* .scroll_div {
  height: calc(100vh - 340px);
  overflow-y: scroll;
  min-height: auto;
} */
button.collapseBtn:hover {
  background: #3A404F;
  color: #fff;
}
.concern-container-ouput-tabs li:last-child {
  margin-left: auto;
}
.concern-container-ouput-tabs li:last-child button {
  border: none;
  padding: 0;
  background: transparent;
}
a.homeBtn img {
  width: 35px;
}
a.homeBtn {
  margin-left: 7px;
}
.fixed-bttn{
  position: fixed;
  bottom: 2px;
  right: 47px;
  z-index: 999;
}
.concern-l .col-lg-12 .comonn-right .concern-container-ouput {
  width: 92%;
}
.now-gutters.concern-l .col-lg-12 .common-left {
  margin-right: 62px !important;
}
.concern-footer.full {
  background: rgb(247 249 251);
}
.generateBtn img {
  filter: brightness(0);
  margin-right: 6px;
}
.float-bttn button img{
  filter: brightness(0);
}
.float-bttn button:last-child {
  background: #E0EDF0;
  color: #3A404F;
  font-weight: 600;
}
.float-bttn button:last-child:hover{
  background: #3a404f;
    color: #fff;
}
.generatefeedbackBttn {
  position: fixed;
  bottom: 15px;
  z-index: 99;
  right: 50%;
  color: #3A404F;
  font-size: 16px;
  font-weight: 700;
  font-family: Poppins;
  border: none;
  background: #87C4D0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px 17px;
}
ul.interviewList {
  height: calc(100vh - 200px);
  overflow-y: scroll;
  padding-bottom: 50px;
}
.scroll_div{
  height: calc(100vh - 280px);
  overflow-y: scroll;
  padding-bottom: 50px;
  padding-right: 6px;
}
.intoduction_list {
  height: calc(100vh - 280px);
  overflow-y: scroll;
  padding-bottom: 50px;
  padding-right: 6px;
}
.step-oneview, .cover-letter{
  height: calc(100vh - 260px);
  overflow-y: scroll;
  padding-bottom: 50px !important;
}
.jobpost-left {
  height: calc(100vh - 150px);
  overflow-y: scroll;
  padding-bottom: 50px;
}
:focus-visible {
  border: none;
  outline: none;
  background: #f7f9fb;
}
.scroll_position {
  height: 300px;
  overflow-y: scroll;
  padding-right: 15px;
  padding-bottom: 20px;
}
.Added_concern li {
  position: relative;
}
.input-bttn-save {
  border: none;
  background: #e0edf0;
  padding: 6px 16px;
  font-size: 19px;
  border-radius: 4px;
}
button.input-bttn-cancel {
  background: transparent;
  border: 1px solid #87C4D0;
  padding: 6px 14px;
  font-size: 19px;
  border-radius: 4px;
  color: #87C4D0;
}
.input-bttn {
  position: absolute;
  top: 9px;
  right: 8px;
  display: flex;
  gap: 7px;
}
.cover-letter-sep h4 .editBttn{
  background: transparent;
  border: none;
  color: #87C4D0;
  text-decoration: underline;
  font-size: 15px;
}
.right-side >div {
  white-space: normal !important;
  color: #3A404F;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 31.153px; 
}

.keyword-card .cv-output {
  height: auto;
  padding: 18px 10px;
  justify-content: space-between;
  width: 100%;
}
.keyword-card .cv-output .success-upload {
  width: 100%;
}
.keyword-card .cv-output .success-upload > div {
  margin-left: auto;
}
.keyword-outbox {
  border-radius: 15px;
  border: 1px dashed #D0D2D9;
  background: #F6FAFB;
  padding: 15px;
  margin: 15px 0;
}
.drag-left {
  background: #fff;
  padding: 15px;
  border-radius: 15px;
  width: 50%;
  border: 1px solid transparent;
  position: relative;
}
.draganddrop-heading {
  background: #FFF1D1;
  border-radius: 5px;
  padding: 15px 10px;
  text-align: center;
  color: #3A404F;
  font-size: 16px;
  font-weight: 600;
  width: 80%;
  margin: -38px auto 25px auto;
}
.drag-key {
  color: #3A404F;
  font-size: 14px;
  font-family: Roboto;
  display: inline-block;
  background: #fff8ea;
  border: 1px solid #FFE19E;
  border-radius: 30px;
  padding: 9px 17px;
  cursor: move;
  margin: 0 10px 10px 0;
}
.draganddrop {
  margin-top: 50px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  min-height: calc(100vh - 500px);
}
.keyword-outbox p {
  color: #32394E;
  font-size: 15px;
  font-family: Roboto;
}
.drag-right {
  background: #F9F9F9;
  padding: 15px;
  border-radius: 15px;
  width: 50%;
  border: 1px solid #ACB3C4;
  position: relative;
  transition: .3s ease-in-out;
}
.drag-right.hover {
  background: #EFF7F9;
  border: 1px dashed #87C4D0;
  transition: .3s ease-in-out;
}
.drag-right .draganddrop-heading {
  background: #E0EDF0;
}
.drop-key {
  color: #3A404F;
  font-size: 14px;
  font-family: Roboto;
  display: inline-block;
  background: #E0EDF0;
  border: 1px solid #87C4D0;
  border-radius: 30px;
  padding: 9px 17px;
  cursor: move;
  margin: 0 10px 10px 0;
}
.c-box .noresultFound {
  width: 95%;
}
.c-box .noresultFoundInner h4 {
  color: #E27454;
  font-size: 20px;
  font-weight: 600;
}
.c-box .noresultFoundInner p {
  margin: 0;
  color: #3A404F;
  font-size: 17px;
  line-height: 1.6;
}
.droptext {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.droptext h5 {
  margin: 0;
  color: #7583A5;
  font-size: 14px;
}
button.AddcommentBtn {
  color: #18A8B2;
  font-size: 14px;
  border: none;
  padding: 0;
  background: transparent;
  font-weight: 500;
  text-decoration: underline;
  margin: 14px 0 5px 25px;
}
.add-comment {
  display: flex;
  align-items: center;
  gap: 13px;
}
.comment-box {
  border-top: 1px solid #D9D9D9;
  padding: 12px 16px;
  margin: 11px 0 0 0;
}
.comment-img {
  width: 5%;
}
.comment-img img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.comment-input {
  width: 95%;
  position: relative;
}
.comment-input input[type="text"] {
  border: 1px solid #ACB3C4;
  border-radius: 30px;
  width: 100%;
  color: #565973;
  font-size: 18px;
  padding: 12px 122px 12px 15px;
}
.comment-input button {
  position: absolute;
  right: 8px;
  top: 7px;
  background: #87C4D0;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 30px;
  padding: 7px 18px;
  cursor: pointer;
}
.reply-comment-area {
  display: flex;
  gap: 13px;
  margin: 10px 0;
}

.reply-comment {
  width: 95%;
  background: #F7F9FB;
  border-radius: 10px;
  padding: 7px;
}
.reply-comment h2 {
  color: #191919;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Roboto';
}
.output-view-body.step-oneview .reply-comment h2 span {
  position: relative;
  top: 0;
  left: 0;
  transform: inherit;
  color: #666;
  font-size: 18px;
  font-weight: 400;
  font-family: 'Roboto';
}
.reply-comment p {
  color: #191919;
  font-size: 18px;
  margin: 0;
  font-family: 'Roboto';
}
.reply-comment p b {
  color: #018CE3;
}
.child-reply {
  padding-left: 47px;
}


.drag-direction {
  align-self: center;
}
.drag-left.hover {
  background: #fafaf4;
  border: 1px dashed #FBBB00;
}
.no-missing-output p {
  font-size: 18px;
  margin: 11px 0 0 0;
}
.no-missing-output {
  text-align: center;
  width: 85%;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loaderBttn {
  color: #3A404F;
  font-size: 16px;
  font-weight: 700;
  font-family: Poppins;
  border: none;
  background: #87C4D0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px 17px;
}
.comment-dropdown button {
  position: absolute;
  right: 28px;
  top: 5px;
  background: transparent;
  border: none;
  padding: 0;
}
.comment-dropdown button::after{
  display: none;
}
button.EmojiToggel {
  position: absolute;
  right: 10px;
  top: 7px;
  border: none;
  background-color: transparent;
  padding: 0;
  width: 41px;
  height: 40px;
}
.Emojipart {
  position: absolute !important;
  right: 56px;
  top: 13px;
  z-index: 9;
}
.Toastify__toast-theme--dark {
  background: #87c4d0 !important;
  color: #3A404F !important;
}
.Toastify__toast-icon svg {
  color: #3A404F !important;
  fill: #3A404F !important;
}
.Toastify__close-button svg{
  color: #3A404F !important;
  fill: #3A404F !important;
}
.Toastify__progress-bar--wrp{
  opacity: 0 ;
}
.concern-container-ouput-tabs li button.regenrateBttn {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.concern-container-ouput-tabs li button.regenrateBttn:hover {
  background: #E0EDF0;
}
.drag-inner {
  min-height: auto;
  max-height: calc(100vh - 600px);
  overflow-y: scroll;
  padding-bottom: 20px;
}
.drag-inner::-webkit-scrollbar-track {
  background: #fff;
}
.concern-footer.forconcern {
  padding: 40px 28px;
}
.cover-letter-sep.fulllength .intro-top {
  width: 12%;
}