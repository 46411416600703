@media screen and (min-width: 1370px) {
    .slider-animate img {
        width: 500px;
    }
    .home-container {
        width: 78%;
        margin: 0 auto;
    }
    h4.specialTxt::before {
        width: 17%;

    }
    h4.specialTxt::after {
        width: 17%;
    }
    .wallet-container {
        width: 1600px;
    }
    .reedem-lft {
        padding-right: 30px;
    }
  }

  @media screen and (min-width:1200px) and (max-width:1367px){
    .landing-story-box h5 {
        font-size: 25px;
    }
    .landing-story-box p {
        font-size: 17px;
    }
    .btn-text h3 {
        font-size: 25px;
      }
      .jobBtn {
        padding: 17px 22px;
        font-size: 17px;
    }
    .home-nav-tabs .nav-link {
        font-size: 16px;
    }
    .home-nav-tabs .nav-link.active {
        font-size: 18px;
    }
    .home-tabs table th {
        font-size: 17px;
      }
      .home-tabs table td {
        font-size: 17px;
    }
    .home-tabs table td span {
        font-size: 17px;
    }
    .preview-modal h2 {
        font-size: 25px;
    }
    .preview-modal h4 {
        font-size: 17px;
    }
    .preview-modal p {
        font-size: 12px;
    }
    .pageHeading h1 {
        font-size: 28px;
    }
    .default-card-header-text h3 {
        font-size: 22px;
      }
      .default-card-header-text p {
        font-size: 15px;
      }
      .input-field-info h4 {
        font-size: 20px;
      }
      .input-field-info p {
        font-size: 16px;
      }
      .linkdinUrl .form-control {
        font-size: 16px;
    }
    .jobsinput-field label {
        font-size: 16px;
    }
    .jobsinput-field .form-control {
        font-size: 16px;
    }
    .output-view-body.step-jobsview p {
        font-size: 15px;
      }
      .themeBtn {
        font-size: 17px;
      }
      .concern-box h3 {
        font-size: 24px;
    }
    .concern-box h4 {
        font-size: 20px;
    }
    .concern-box .ConcernList li {
        font-size: 16px;
    }
    .concern-container-ouput-tabs .nav-link {
        font-size: 17px;
    }
    ul.interviewList li h3 {
        font-size: 21px;
    }
    ul.interviewList li p {
        font-size: 15px;
        line-height: 22.153px;
    }
    .onboard_slider h4 {
        font-size: 26px;
    }
    .onboard_slider p {
        font-size: 17px;
        line-height: 25px;
      }
      .onboard_slider img {
        height: 160px;
      }
      .onboard-container-big {
        padding: 30px 0 30px 0;
    }
    .onboard_slider .nextBtn {
        font-size: 20px;
        padding: 13px 40px;
    }
    .output-view-body.step-oneview ul {
        max-height: 80vh;
    }
    .cover-letter {
        max-height:80vh;
      }
      img.logo {
        width: 190px;
    }
    .Added_concern .form-control {
        font-size: 16px;
        padding: 10px 15px;
    }
    .concern-container-ouput-tabs .nav-link.active {
        font-size: 18px;
    }
    .slider-animate-mid img {
        height: 170px;
        width: auto;
    }
    .concerngenerateloader_middle h5 {

        font-size: 16px;
        line-height: 25px;
    }
    .concerngenerateloader_middle h3 {
        font-size: 28px;
        line-height: 40px;
        margin: 0 0 13px 0;
    }
    .concerngenerateloader_top {
        padding: 15px 15px;
    }
    .signup-container {
        width: 88%;
        height: 90vh;
    }
    .signup-container-rht {
        width: 85%;
    }
    .nodata-home {
        margin: 42px 0;
        padding: 35px 20px;
    }
    .output-view-body.step-jobsview h3 {
        font-size: 16px;
    }
    .output-view-body.step-oneview ul li::after {
        transform: translate(-3.5%, -48%) rotate(45deg);
        left: 3.5%;
    }
    .output-view-body.diff-output {
        max-height: 100vh;
      }
  }

  @media screen and (min-width:991px) and (max-width:1199px){
    .slider-animate h3 {
        font-size: 1rem;
    }
    .slider-animate img {
        width: 272px;
    }
    .signup-container-rht h3 {
        font-size: 21px;
    }
    .signup-container-rht button {
        padding: 8px 23px;
        font-size: 14px;
    }
    .signup-container-rht button img {
        width: 28px;
    }
    .signup-container-rht h5 {
        font-size: 13px;
    }
    .signup-container-rht h6 {
        white-space: break-spaces;
    }
    img.logo {
        width: 150px;
    }
    .onboard_slider {
        padding: 5px 9%;
    }
    .onboard_slider img {
        height: 100px;
    }
    .onboard_slider h4 {
        font-size: 25px;
    }
    .onboard_slider p {
        font-size: 13px;
    }
    .onboard_slider .nextBtn {
        font-size: 17px;
        padding: 12px 35px;
    }
    .onboard-container-big {
        flex: 80% 1;
        padding: 38px 0 30px 0;

    }
    .btn-text h3 {
        font-size: 20px;
    }
    .home-nav-tabs .nav-link.active {
        font-size: 17px;
    }
    .home-nav-tabs .nav-link {
        font-size: 15px;

    }
    .home-tabs table th {
        font-size: 16px;
    }
    .home-tabs table td {
        font-size: 16px;
    }
    .home-tabs table td span {
        font-size: 16px;
    }
    .jobBtn {
        padding: 16px 22px;
        font-size: 17px;
    }
    .preview-modal h2 {
        color:#3A404F;
        font-size: 24px;
    }
    .pageHeading h1 {
        font-size: 30px;
    }
    .default-card-header-text h3 {
        font-size: 16px;
    }
    .default-card-header-text p {
        font-size: 14px;
    }
    .input-field-info h4 {
        font-size: 15px;
        line-height: 19.153px;
    }
    .input-field-info p {
        font-size: 14px;
    }
    .linkdinUrl .form-control {
        font-size: 16px;
    }
    .jobsinput-field label {
        font-size: 16px;
    }
    .jobsinput-field .form-control {
        font-size: 16px;
    }
    .default-card-header-text {
        width: 59%;
    }
    .no-data h5 {
        font-size: 16px;
    }
    .output-view-body.step-jobsview {
        max-height: 536px;
    }
    .output-view-body.step-jobsview p {
        font-size: 14px;
    }
    .header-right label {
        font-size: 16px;
    }
    .header-right .dropdown-menu a {
        font-size: 15px;
    }
    .header-dropdown button.signOutBtn {
        font-size: 15px;
    }
    .dopdown-top h5 {
        font-size: 15px;
    }
    .dopdown-top span {
        font-size: 12px;
    }
    .themeBtn {
        font-size: 16px;
    }
    .concern-container-ouput-tabs {
        padding: 16px 15px 27px 15px;
    }
    .concern-container-ouput-tabs .nav-link {
        font-size: 17px;
        padding: 10px 14px;
    }
    .concern-container-ouput-tabs .nav-link.active {
        font-size: 17px;
    }
    .output-view-body.step-oneview ul {
        max-height: 536px;
    }
    .concern-box h3 {
        font-size: 22px;
    }
    .concern-box h4 {
        font-size: 16px;
    }
    .concern-box .ConcernList li {
        padding: 10px 15px 10px 48px;
        font-size: 15px;
        line-height: 22.153px;

    }
    button.saveBtn {
        font-size: 17px;
        line-height: 27.153px;
        padding: 10px 33px;
    }
    .concern-container-ouput {
        width: 95%;
        margin-top: 40px;
    }
    ul.interviewList li h3 {
        font-size: 18px;
    }
    ul.interviewList li p {
        font-size: 15px;
        line-height: 22.153px;
    }
    ul.intoduction_list li p {
        font-size: 15px;
        line-height: 24.153px;
    }
  }

  @media screen and (min-width:320px) and (max-width:575px){
    .login-page .signup-container > .row .col-lg-8 {
        order: 2;
    }
    .login-page .signup-container > .row .col-lg-4 {
        order: 1;
    }
    .login-page {
        min-height: auto;
    }
    .signup-container {
        width: 90%;
        height: auto;
        margin: 10px 0;
    }
    .signup-container-rht {
        height: 100%;
        padding: 14px 19px;
        width: 90%;
        margin-left: inherit;
        margin: 20px auto;
    }
    .signup-container-rht-inner {
        margin-top: 0;
    }
    .signup-container-rht h3 {
        font-size: 16px;
        margin: 0 0 14px 0;
    }
    .signup-container-rht button {
        padding: 4px 15px;
        font-size: 12px;
        margin: 0 auto;
    }
    .signup-container-rht button img {
        width: 29px;
    }
    .signup-container-rht h6 {
        margin-top: 18px;
    }
    .slider-animate h3 {
        font-size: 1rem;
    }
    .slider-animate img {
        width: 183px;
    }
    img.logo {
        width: 106px;
    }
    .header .container-fluid {
        padding: 0 12px;
    }
    .header-right { 
        gap: 14px;
        align-items: center;
    }
    .header-right label {
        font-size: 10px;
    }
    .header-dropdown button {
        border: none;
        width: 28px;
        height: 28px;
        font-size: 10px;
    }
    .onboard-container-outer {
        height: auto;
        display: flex;
        width: 100%;
        align-items: center;
    }
    .onboard-container {
        gap: 13px;
        width: 100%;
    }
    .onboard-container-big {
        padding: 20px 0 30px 0;
    }
    .onboard_slider {
        padding: 20px 6%;
        text-align: center;
    }
    .onboard_slider img {
        height: 103px;
    }
    .onboard_slider p {
        font-size: 12px;
        line-height: 18px;
    }
    .onboard_slider h4 {
        font-size: 19px;
    }
    .onboard_slider .nextBtn {
        font-size: 14px;
        padding: 7px 31px;
    }
    h5.subscriptionText {
        margin: 8px 0 0 0;
        font-size: 10px;
    }
    h5.subscriptionText a {

        font-size: 7px;
    }
    marquee {
        font-size: 11px;
    }
    .header-right .dropdown-menu a {
        font-size: 12px;

        padding: 3px 15px;
    }
    .dopdown-top span {
        border: none;
        width: 28px;
        height: 28px;
        font-size: 12px;
    }
    .dopdown-top h5 {
        font-size: 14px;
    }
    .header-dropdown button.signOutBtn {
        font-size: 12px;
        padding: 7px 15px;
    }
    .header-right .dropdown-menu {
        min-width: 178px;
    }
    .header-right .language-dropdown .dropdown-menu {
        min-width: 199px;
        right: 32px;
        top: 42px;
        width: 243px;
    }
    .language-dropdown .dropdown-menu h5 {
        font-size: 10px;
    }
    .language-dropdown .dropdown-menu li {
        font-size: 12px;
        padding: 6px 12px;
    }
    .l-note p {
        font-size: 13px;

    }
    .language-information h4 {
        font-size: 12px;
    }
    .landing-story-box {
        padding: 22px;
        flex-direction: column;
    }
    .landing-story-box p {
        font-size: 20px;
        line-height: 22px;
    }
    .jobBtn {
        padding: 17px 24px;
        font-size: 14px;
        line-height: 16px;
        gap: 8px;
    }
    .btn-text h3 {

        font-size: 18px;
    }
    .home-nav-tabs .nav-link.active {
        font-size: 16px;
    }
    .home-nav-tabs .nav-link {
        font-size: 15px;
    }
    .home-tabs table th {
        font-size: 16px;
        padding: 15px 13px;

    }
    .home-tabs table td {
        font-size: 14px;
        padding: 15px 13px;

    }
    .home-tabs table td span {
        font-size: 14px;
    }
    .preview-modal h2 {
        font-size: 19px;
        line-height: 24px;
    }
    .preview-modal.p-5.modal-body {
        padding: 1rem !important;
    }
    .preview-modal::before {
        display: none;
    }
    .main-layout .container-fluid {
        padding-left: 0 !important;
        min-height: auto;
        padding-right: 0;
    }
    .pageHeading h1 img {
        width: 29px;
    }
    .pageHeading h1 {
        font-size: 17px;
    }
    .default-card-header-text h3 {
        font-size: 16px;
    }
    .default-card-header-text p {
        font-size: 11px;
    }
    .default-card-header-text {
        width: 55%;
    }
    .input-field-info h4 {
        font-size: 16px;
        line-height: 14.153px;
    }
    .job-post-tab-button button {
        font-size: 13px;
    }
    .linkdinUrl .form-control {
        font-size: 17px;
    }
    .jobsinput-field label {
        font-size: 15px;
    }
    .jobsinput-field .form-control {
        font-size: 16px;
    }
    .themeBtn {
        font-size: 15px;
    }
    .concerngenerateloader_middle h5 {
        font-size: 17px;
        line-height: 26px;
    }
    h4.specialTxt {
        font-size: 14.333px;
    }
    .input-field-info p {
        font-size: 16px;
    }
    .success-upload h4 {
        font-size: 13px;
    }
    button.replaceCVBtn {
        font-size: 10px;
        padding: 9px 10px;
    }
    .cv-output {
        height: 101px;
        padding: 5px;
    }
    .concern-container-ouput-tabs .nav-link {
        font-size: 15px;
        padding: 10px 11px;
    }
    .concern-container-ouput-tabs .nav-link.active {
        font-size: 16px;
    }
    .cover-letter-sep p {
        font-size: 16px;
    }
    pre {
        font-size: 16px;
    }
    .lft-number {
        left: -21px;
    }
    .concern-box h3 {
        top: -14px;
        font-size: 21px;
    }
    .concern-box h4 {
        font-size: 18px;
    }
    .concern-box .ConcernList li {
        padding: 10px 15px 10px 45px;
        font-size: 15px;
        line-height: 20.153px;
    }
    .concerndefaultloader {
        width: 75%;
        position: relative;
        top: 0;
        left: 0;
        transform: inherit;
    }
    button.addBtn {
        font-size: 17px;
        padding: 10px 15px;

    }
    button.saveBtn {
        font-size: 17px;
        padding: 9px 39px;
    }
    .des-result ul li {
        font-size: 14px;
    }
    button.generateBtn {
        font-size: 15px;
    }
    .concern-container-ouput-tabs {
        padding: 16px 20px 27px 20px;
    }
    ul.interviewList li h3 {
        font-size: 18px;
    }
    ul.interviewList li p {
        font-size: 14px;
        line-height: 21.153px;
    }
    .concern-l .common-left {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }
    .concern-l .pageHeading  button.backConcernBtn {
        line-height: 128.173%;
        padding: 10px 10px;
    }
    .concern-l .pageHeading  button.tabConcernBtn {
        font-size: 14px;

        line-height: 126.173%;
        padding: 12px 10px;
        margin-left: 10px;
    }
    .concern-container-ouput {
        width: 95%;
        margin-top: 24px;
    }
    .lft-number {
        display: none;
    }
    .question-modal-top h4 {
        font-size: 20px;
    }
    .question-modal-top h5 {
        font-size: 13px;
        line-height: 22.153px;
    }
    .question-modal-middle p {
        font-size: 13px;
        line-height: 21.153px;
    }
    .question-modal-middle textarea.form-control {
        font-size: 13px;
    }
    .my_ans h4 {
        font-size: 18px;
    }
    ul.interviewList li .my_ans p {
        font-size: 14px !important;
        line-height: 23.153px;
    }
    .subscription-container h3 {
        font-size: 21px;
    }
    .subscription-box {
        margin-bottom: 30px;
    }
    .subscription-container  p {
        margin: 0 0 0 0;
        font-size: 15px;
    }
    .subscription-box h4 {
        margin: 0 0 8px 0;
        font-size: 20px;
    }
    .wallet-container {
        padding: 24px 15px;
        max-width: 100%;
    }
    .refer-container h3 {
        color: #263b75;
        font-size: 28px;
        font-weight: 700;
    }
    .refer-url input.form-control {
        width: 100%;
    }
    .refer-url {
        flex-direction: column;
    }
    .refer-url-right {
        width: 100%;
    }
    .get-box {
        min-height: auto;
        margin-bottom: 30px;
    }
    .subscription-innercontainer {
        padding-left: 0px;
    }
  }

  @media screen and (min-width:576px) and (max-width:991px){
    .signup-container {
        width: 92%;
        margin: 16px auto;
        height: auto;
        padding: 20px 15px;
    }
    .slider-animate h3 {

        font-size: 1rem;
    }
    .slider-animate img {
        width: 268px;
    }
    .signup-container-rht {
        padding: 15px 16px;
        width: 100%;
        margin-left: auto;
    }
    .signup-container-rht-inner {
        margin-top: 15px;
    }
    .signup-container-rht h3 {
        font-size: 16px;
        margin: 0 0 25px 0;
    }
    .signup-container-rht button {
        padding: 8px 12px;
        font-size: 11px;
        gap: 6px;
        margin: 0 auto;
    }
    .signup-container-rht button img {
        width: 28px;
    }
    .subscription-modal.language_setup_modal .modal-body {
        padding: 17px 20px;
    }
    .subscription-modal .modal-dialog .modal-body h4 {
        font-size: 22px;
    }
    .subscription-modal .modal-dialog .modal-body p {
        font-size: 16px;
        line-height: 21px;

    }
    .subscription-modal .modal-dialog .modal-body ul {
        gap: 3px;
    }
    .subscription-modal .modal-dialog .modal-body ul button {
        background: transparent;
        width: 80px;
        height: 80px;
        border: 4px solid transparent;
        padding: 5px;
    }
    .BtnStyle_two {
        font-size: 14px;
        padding: 8px 41px;
    }
    .language_setup_modal .modal-body h6 {
        font-size: 10px;
    }
  }


/* 
42 - 38
30 - 25
20 - 17
28 - 24
24 - 20
16 - 13
*/

